import React, { createContext, useContext, useState } from "react"

const SuccessMessageContext = createContext<
  | { message: string | null; setMessage: (msg: string | null) => void }
  | undefined
  // eslint-disable-next-line no-undefined
>(undefined)

export const SuccessMessageProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [message, setMessage] = useState<string | null>(null)

  return (
    <SuccessMessageContext.Provider value={{ message, setMessage }}>
      {children}
    </SuccessMessageContext.Provider>
  )
}

export const useSuccessMessage = () => {
  const context = useContext(SuccessMessageContext)

  if (!context) {
    throw new Error(
      "useSuccessMessage must be used within a SuccessMessageProvider",
    )
  }

  return context
}
