import { HOME_PATH } from "@cyna/common/routes"
import { Link } from "@cyna/components/base/Link"
import { Typo } from "@cyna/components/base/Typo"
import { Sidebar, SidebarHeader } from "@cyna/components/ui/sidebar"
import pkg from "@cyna/package"
import { ReactNode } from "react"

export const MainMenu = ({ children }: { children: ReactNode }) => (
  <Sidebar className="z-20">
    <SidebarHeader>
      <Typo
        as="h1"
        className="flex items-end justify-between px-0.5 py-2.5 leading-none"
      >
        <h1>
          <Link path={HOME_PATH} styless>
            Cyna Platform
          </Link>
          <span className="font-mono text-xs font-normal">v.{pkg.version}</span>
        </h1>
      </Typo>
    </SidebarHeader>
    {children}
  </Sidebar>
)
