export const flattenObject = (obj: object, scope: string[]) =>
  Object.entries(obj).flatMap(
    ([key, value]: [string, string | object]): string[] => {
      if (typeof value === "object") {
        return [key, flattenObject(value, scope)].flat()
      }

      return [key, scope].flat()
    },
  )
