"use client"

import { LANGUAGE_NAMES, SUPPORTED_LANGUAGES } from "@cyna/common/constants"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@cyna/components/ui/dropdown-menu"
import {
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@cyna/components/ui/sidebar"
import { cn } from "@cyna/components/utils"
import { MainMenu } from "@cyna/web/components/mainMenu/MainMenu"
import { useI18n } from "@cyna/web/hooks/useI18n"
import { ChevronsUpDownIcon, LanguagesIcon, SunMoonIcon } from "lucide-react"
import { useTheme } from "next-themes"
import { useCallback } from "react"

export const PublicMainMenu = () => {
  const [t, { language }] = useI18n()
  const { setTheme, theme: currentTheme } = useTheme()
  const changeLanguage = useCallback((newLanguage: string) => {
    window.history.replaceState(
      null,
      "",
      `/${newLanguage}${window.location.pathname.slice(3)}${window.location.search}`,
    )
    window.location.reload()
  }, [])

  return (
    <MainMenu>
      <SidebarContent />
      <SidebarFooter>
        <SidebarGroup>
          <SidebarGroupLabel></SidebarGroupLabel>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton>
                    <SunMoonIcon className="h-4 w-4" />
                    {t("common:mainMenu.footer.theme")}
                    <ChevronsUpDownIcon className="ml-auto h-4 w-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width]">
                  <DropdownMenuRadioGroup onValueChange={setTheme}>
                    {["dark", "light", "system"].map((theme) => (
                      <DropdownMenuRadioItem
                        key={theme}
                        value={theme}
                        className={cn(theme === currentTheme && "bg-muted")}
                      >
                        {t(`common:theme.${theme}`)}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton>
                    <LanguagesIcon className="h-4 w-4" />
                    {t("common:mainMenu.footer.language")}
                    <ChevronsUpDownIcon className="ml-auto h-4 w-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width]">
                  <DropdownMenuRadioGroup onValueChange={changeLanguage}>
                    {SUPPORTED_LANGUAGES.map((lang) => (
                      <DropdownMenuRadioItem
                        key={lang}
                        value={lang}
                        className={cn(lang === language && "bg-muted")}
                      >
                        {LANGUAGE_NAMES[lang]}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarFooter>
    </MainMenu>
  )
}
