"use client"

import { PublicMainMenu } from "@cyna/web/components/mainMenu/PublicMainMenu"
import { SuccessMessageProvider } from "@cyna/web/providers/SuccessMessageContext"
import { ReactNode } from "react"

const RootLayout = ({
  children,
}: Readonly<{
  children: ReactNode
}>) => (
  <SuccessMessageProvider>
    <PublicMainMenu />
    <main className="flex h-full grow flex-col overflow-auto">{children}</main>
  </SuccessMessageProvider>
)

export default RootLayout
